.storage-bottom-sheet {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 20px 20px;

	.storage__icon, h1 {
		margin-bottom: 20px;
        text-align: center;
	}

    &__button-container {
        width: 100%;

        button {
			display: block;
			margin-bottom: 10px;
            font-family: var(--font-type-ubuntu-bold);

			&:last-child {
				margin-bottom: 0;
			}
		}
    }

}
