// Open Sans
@font-face {
	font-family: 'OpenSans-Light';
	src: url('./OpenSans/OpenSans-Light.ttf');
}

@font-face {
	font-family: 'OpenSans-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('./OpenSans/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'OpenSans-Italic';
	src: url('./OpenSans/OpenSans-Italic.ttf');
}

@font-face {
	font-family: 'OpenSans-SemiBold';
	src: url('./OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
	font-family: 'OpenSans-Bold';
	src: url('./OpenSans/OpenSans-Bold.ttf');
}

// Ubuntu
@font-face {
	font-family: 'Ubuntu-Light';
	src: url('./Ubuntu/Ubuntu-Light.ttf');
}

@font-face {
	font-family: 'Ubuntu-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('./Ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
	font-family: 'Ubuntu-Italic';
	src: url('./Ubuntu/Ubuntu-Medium.ttf');
}

@font-face {
	font-family: 'Ubuntu-Medium';
	src: url('./Ubuntu/Ubuntu-Italic.ttf');
}

@font-face {
	font-family: 'Ubuntu-Bold';
	src: url('./Ubuntu/Ubuntu-Bold.ttf');
}
