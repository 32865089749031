.confirm-delete {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 30px 20px;

	h1 {
		text-align: center;
		padding: 14px 40px 30px 40px;
	}

	p {
		margin-bottom: 40px;

		text-align: center;

		color: var(--color-boldGrey);
	}

	&__buttons {
		width: 100%;

		button {
			display: block;

			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
