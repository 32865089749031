.language-container {
	padding-top: 27px;

	.list-item {
		span {
			font-family: var(--font-type-openSans-regular);
			font-size: var(--font-size-large);
			color: var(--color-boldGrey);
		}

		&.list-item--check {
			span {
				font-family: var(--font-type-openSans-semiBold);
			}
		}
	}
}
