.slide-enter {
    transform: translateY(100%);
}

.slide-enter-active {
    transform: translateY(0);
    transition: transform 250ms ease-in-out;
}

.slide-appear {
    transform: translateY(100%);
}

.slide-appear-active {
    transform: translateY(0);
    transition: transform 250ms ease-in-out;
}


.slide-exit {
    transform: translateY(0);
}

.slide-exit-active {
    transform: translateY(100%);
    transition: transform 250ms ease-in-out;
}
