.store-selection {
	height: var(--metric-windowHeight);
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	.header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;

		flex-shrink: 1;

		.logout-cta {
			background-color: transparent !important;
			height: 35px;
			width: 35px;
			border-radius: 50px;
			z-index: 101;
		}
	}

	.store-selection__content {
		flex-grow: 1;
	}

	.loading-container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.store-selection__message-container {
		position: absolute;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		padding: 1rem;

		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		.message-container__message {
			text-align: center;
			font-size: var(--font-size-xlarge);
			padding-bottom: 1rem;

			line-height: 1.5rem;

			white-space: pre-wrap;
		}

		button {
			margin-top: 1rem;
		}
	}

	.invalid-store-message {
		display: block;
		padding-bottom: 1rem;
		padding: 0 1rem 1rem 1rem;
		line-height: 1.2;
	}

	.store-selection__cards-container {
		padding: 0 1rem;

		.cards-container__card {
			display: flex;
			flex-direction: row;
			margin: .5rem 0;

			.card__logo {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-grow: 0;
				flex-shrink: 1;

				margin: 1rem;
			}

			.card__icon{
				width: 50px;
				height: 50px;
			}

			.card__logo {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-grow: 0;
				flex-shrink: 1;

				margin: 1rem;
			}

			.card__description {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				flex-grow: 1;

				margin: 0 1rem;

				color: var(--color-nightBlue);

				.description__type {
					font-family: var(--font-type-openSans-regular);
					font-size: var(--font-size-normal);
				}

				.description__name {
					font-family: var(--font-type-openSans-bold);
					font-size: var(--font-size-normal);
				}
			}
		}
	}

	.store-selection__app-version {
		flex-shrink: 1;
		padding: 1rem;
	}
}
