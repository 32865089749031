.order-scanner {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex: 1;

	.order-scanner__message {
		flex: 1;
		font-size: var(--font-size-large);
		color: var(--color-darkGrey);
		text-align: center;
		padding: 1rem 1rem 0 1rem;
		line-height: 1.2;
		white-space: pre-wrap;
		b,
		strong {
			font-family: var(--font-type-openSans-bold);
		}
	}

	.order-scanner__scan-container {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;

		margin: 1rem;
	}

	.order-scanner__button-container {
		flex: 1;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
