.input {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	input {
		width: 100%;

		padding: 1.5rem 1rem .75rem 1rem;

		outline: 0;
		border: 1px solid var(--color-lightGrey);
		border-radius: 10px;
		box-sizing: border-box;

		font-family: var(--font-type-openSans-regular);
		color: var(--color-text);
		font-size: var(--font-size-small);
	}

	label {
		font-size: var(--font-size-small);
		font-family: var(--font-type-openSans-regular);
		color: var(--color-midGrey);

		padding: 0 1rem;

		pointer-events: none;

		position: absolute;
		transform: translate(0, 1.25rem) scale(1);
		transform-origin: top left;
		transition: all 0.2s ease-out;

		&.disabled {
			transition: unset;
		}
	}

	&:focus-within label {
		transform: translate(0, .5rem) scale(0.70);
	}

	& .active {
		transform: translate(0, .5rem) scale(0.70);
	}

	& .has-value {
		transform: translate(0, .5rem) scale(0.70);
	}

	.error {
		font-size: var(--font-size-small);
		font-family: var(--font-type-openSans-regular);
		color: var(--color-red);
		margin-top: 4px;
		margin-left: 1rem;
	}
}
