.order-details {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: calc(var(--metric-windowHeight) - var(--metric-tabBarHeight) - 3rem);
	overflow: hidden;

	.order-details__empty-message {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		color: var(--color-nightBlue);
		font-family: var(--font-type-openSans-bold);
	}

	.order-details__section-list {
		flex: 1;
		overflow: scroll;
		padding-bottom: 1rem;

		.section-list__header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 2rem 1rem 1rem 1rem;

			&__order-refund {
				padding: 6px 0 6px 0;
				min-width: 45px;
				max-width: 45px;
			}
		}

		.section-list__row {
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow: hidden;
			margin: 0 1rem;

			position: relative;
			background-color: var(--color-purewhite);

			border-bottom: 1px solid #f5f5f5;

			&.section-list__row--first {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}

			&.section-list__row--last {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				border-bottom: unset;
			}

			.row__content {
				padding: 1rem 0;
				margin: 0 1rem;
				display: flex;
				align-items: center;
				flex: 1;
				color: var(--color-nightBlue);

				align-self: stretch;

				&.row__content--red {
					color: var(--color-red);
				}

				&.row__content--green {
					color: var(--color-green);
				}

				.content__quantity {
					flex-shrink: 1;
					padding-right: 1rem;

					width: 20px;

					font-size: var(--font-size-xlarge);
					font-family: var(--font-type-openSans-bold);
				}

				.content__name {
					flex-grow: 1;
					font-family: var(--font-type-openSans-regular);
					font-size: var(--font-size-normal);
				}
			}
		}
	}

	.order-details__button-container {
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		margin: 0 1rem 1rem 1rem;

		.button-container__scan {
			padding: 12px;

			.scan__icon {
				margin-right: .5rem;
			}
		}

		.button-container__action-container {
			display: flex;
			align-items: center;
			height: 100%;
			margin-top: 1rem;

			* {
				min-width: unset;
				font-family: var(--font-type-ubuntu-bold);
				text-align: center;
				height: 100%;
				@media (max-width: 360px) {
					font-size: var(--font-size-small);
				}
			}

			.action-container__decline {
				flex: 1;
				background-color: var(--color-red);
			}

			.action-container__support {
				flex-shrink: 1;
				background-color: var(--color-nightBlue);
				margin: 0 1rem;
				padding: 12px;
				height: unset;
				align-self: center;
			}

			.action-container__force-close {
				flex: 1;
				background-color: var(--color-nightBlue);
			}
		}
	}
}
