.square {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: var(--color-purewhite);
	font-weight: bold;
	line-height: 1;

	border-radius: 10px;

	&__hour {
		font-size: 17px;
	}

	&__icon {
	}

	&__info {
		font-size: 10px;
		margin-top: 6px;
	}
}
