@import '~reset-css/reset.css';

html {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: var(--metric-windowHeight);

	overscroll-behavior: none;
}

body {
	margin: 0 auto;
	font-family: var(--font-type-openSans-regular);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: var(--metric-windowHeight);

	overscroll-behavior: none;

	#root {
		display: flex;
		flex-direction: column;

		position: relative;
		background: var(--color-pageBackground);

		height: var(--metric-windowHeight);
	}

	h1 {
		font-size: var(--font-size-xxxlarge);
		font-family: var(--font-type-ubuntu-bold);
		color: var(--color-nightBlue);
	}

	h2 {
		font-size: var(--font-size-xlarge);
		font-family: var(--font-type-ubuntu-bold);
		color: var(--color-nightBlue);
	}

	button {
		outline: none;
	}

	.noselect {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
	}
}

.ptr,
.ptr__children {
	flex: 1;
	width: unset;
	display: flex;
	flex-direction: column;
}

* {
	margin: 0;
	padding: 0;
}

.toast {
	max-width: calc(100vw - 25vw - 1rem); // device width  - margin right - margin left
	margin-left: 25vw;
	margin-top: 5vh;
	margin-right: 1rem;

	background-color: var(--color-primary);

	font-size: var(--font-size-normal);
	font-family: var(--font-type-ubuntu-regular);
}
