.sheet {
	z-index: 100;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background: var(--color-purewhite);
	touch-action: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 1rem;
	overflow: hidden;
}
