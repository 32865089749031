.delivery-notes {
	display: flex;
	flex-direction: column;
	align-items: center;

	.delivery-notes__icon {
		margin: 2rem 0 1rem 0;
	}

	.delivery-notes__delivery-details {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 1rem 0;
		align-self: stretch;

		.delivery-details__detail {
			display: inline-flex;
			flex-basis: 500px;
			margin-left: .5rem;
			justify-content: center;
			align-items: center;
		}
	}

	.delivery-notes__form {
		display: flex;
		flex-direction: column;
		width: 100%;
		.form__textfield {
			flex: 1;
			margin: 1rem 0;
		}

		.form__button-icon {
			.button__button-icon {
				margin-right: .5rem;
			}
		}
	}
}
