.navbar-header {
	background-color: var(--color-purewhite);
	box-shadow: 0 1px 0 rgba(210, 210, 210, 0.25);

	&__content {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&__back-button {
			display: flex;
			align-items: center;
		}

		&__title-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__text-wrapper {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
		}

		&__icon {
			margin-right: 10px;
		}

		&__title {
			font-size: var(--font-size-xlarge);
			color: var(--color-nightBlue);
			text-align: center;
		}

		&__subtitle {
			font-family: var(--font-type-ubuntuRegular);
			font-size: var(--font-size-small);
			color: var(--color-nightBlue);
			text-align: center;
			padding-top: 4px;
		}

		&__right {
			width: 24px;
		}
	}
}
