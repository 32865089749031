.outgoing-change-status {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 20px 20px;

	.status__icon, h1 {
		margin-bottom: 20px;
	}

	.status__info {
		background-color: var(--color-veryLightGrey);
		width: 100%;
		padding: 20px 0;
		text-align: center;
		border-radius: 10px;
		margin-bottom: 22px;

		> h2 {
			margin-bottom: 12px;
		}

		.info__name {
			font-family: var(--font-type-ubuntuRegular);
			color: var(--color-nightBlue);
		}

		.info__extra {
			display: flex;
			justify-content: center;
			margin-top: 14px;

			.extra__row {
				display: flex;
				align-items: center;
				margin: 0 15px;

				.info__icon {
					margin-right: 10px;
				}
			}
		}
	}

	.status__buttons {
		width: 100%;

		button {
			display: block;

			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
