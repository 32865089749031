.refundExport-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	flex: 1;
	overflow: hidden;
	padding: 34px 20px 20px 20px;
	.form{
		width: 100%;
		.form__field{
			margin-bottom: 1rem;
		}
		.button{
			margin-top: 2rem;
		}
	}
	.icon, h1{
		margin-bottom: 20px;
		fill: var(--color-nightBlue)
	}
	.refund__info{
		margin-bottom: 22px;
		> h2{
			margin-bottom: 12px;
		}
	}


}

.error{
	font-size: var(--font-size-small);
	font-family: var(--font-type-openSans-regular);
	color: var(--color-red);
	margin-top: 4px;
	margin-left: 1rem;
}
