.order-icon-label {
	display: flex;
	align-items: center;
	width: 100%;

	.order-icon-label__icon {
		flex-shrink: 1;
		box-sizing: border-box;
		margin-right: .5rem;
	}

	.order-icon-label__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		color: var(--color-text);
		font-size: var(--font-size-small);

		@media (max-width: 320px) {
			font-size: .65rem;
		}


		.content_number {
			font-family: var(--font-type-openSans-bold);
		}

		.content_unit {
			font-family: var(--font-type-openSans-regular);
			text-transform: uppercase;
			display: inline-block;
			overflow: hidden;
			max-width: 90%;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
