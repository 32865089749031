.tab {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;


	.tab__indicator {
		height: 3px;
		background-color: var(--color-brightBlue);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.tab__link {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		font-family: var(--font-type-openSans-regular);
		text-decoration: none;
		font-size: var(--font-size-xxsmall);
		color: var(--color-nav);
		text-align: center;

		&.active {
			color: var(--color-brightBlue);
			font-family: var(--font-type-openSans-bold);
		}

		&.tab__link--disabled {
			opacity: .5;
		}

		.link__icon {
			padding-bottom: .25rem;
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.link__label {
			flex-shrink: 1;

		}
	}
}
