.input-add-list-item {
	.input-add__icon-wrapper {
		display: flex;
	}

	.content__input {
		font-family: var(--font-type-openSans-regular);
		font-size: var(--font-size-large);
		color: var(--color-boldGrey);
		background-color: transparent;

		flex-grow: 1;
		outline: none;
		border: none;

		&::placeholder {
			color: var(--color-midGrey);
		}

		&:disabled {
			opacity: .5;
		}
	}
}
