.incoming-container {
	position: relative;

	flex: 1;
	overflow: hidden;

	.pull-to-refresh {
		flex: 1;
	}

	.incoming-container__warning {
		background-color: var(--color-warmYellow);
		display: flex;
		flex-direction: row;
		padding: .5rem;
		margin: 1rem 0;

		.warning__icon {
			margin-left: 6px;
			margin-right: .5rem;
		}

		.warning__text {
			flex-grow: 1;
			flex-wrap: wrap;

			font-size: var(--font-size-normal);
			font-family: var(--font-type-ubuntu-regular);
			color: var(--color-darkOrange);
		}
	}

	.incoming-container__order-info {
		margin: 1rem 0;
		display: flex;
		justify-content: space-evenly;
		align-items: stretch;

		.order-info__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 1rem;
			flex: 1;

			&:nth-child(even) {
				margin-left: 1rem;
			}

			.item__number {
				text-align: left;
				font-size: var(--font-size-xxxlarge);
				font-family: var(--font-type-ubuntu-bold);
				color: var(--color-nightBlue);

				margin-bottom: .5rem;
			}

			.item__text {
				font-size: var(--font-size-large);
				font-family: var(--font-type-openSans-regular);
				color: var(--color-nightBlue);
			}
		}
	}

	.incoming-container__orders {
		margin: 1rem 0;
		box-sizing: border-box;
		border-radius: 10px;
		overflow: hidden;

		&.isCompleted {
			opacity: .25;
		}

		.orders__list {
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
			background-color: var(--color-purewhite);
			overflow: visible;

			.list__item {
				flex: 1;
				box-shadow: none;
				border-radius: 0;
				border-bottom: 1px solid #f5f5f5;

				.item__content {
					flex: 1;
					box-sizing: border-box;

					@media (max-width: 320px) {
						margin-right: .5rem;
						&:last-child {
							margin-right: unset;
						}
					}
				}
			}
		}
	}
}

.button-container {
	position: fixed;
	bottom: calc(var(--metric-tabBarHeight) + 1rem);
	left: 2rem;
	right: 2rem;

	@media (min-width: 768px) {
		left: calc((100vw - 768px) / 2 + 2rem);
		right: calc((100vw - 768px) / 2 + 2rem);
	}
}

.safe-area-scroll {
	padding-bottom: calc(var(--metric-tabBarHeight) + 1rem) !important;
}

.wrapper {
	overflow: scroll;

	flex: 1;

	padding: 0 1rem;
}
