.tabHeader {
	background-color: var(--color-purewhite);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

	.tabHeader__content {
		display: flex;

		.content__icon {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 1;
			padding-right: 1rem;
		}

		.content__text {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			font-size: var(--font-size-normal);
			color: var(--color-nightBlue);
			white-space: pre-wrap;

			.text__description {
				padding: .5rem 0;

				.text__name {
					font-family: var(--font-type-ubuntu-bold);
				}

				.text__type {
					font-family: var(--font-type-ubuntu-regular);
				}
			}
		}

		.text__lastSynced {
			font-family: var(--font-type-ubuntu-regular);
			font-size: var(--font-size-xsmall);
			color: var(--color-nightBlue);
			display: block;
		}
	}
}
