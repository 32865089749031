.settings-header {
	padding: 1.25rem;
}

.settings-container {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.settings-app-version {
	padding-bottom: .5rem;
}
