.indicator-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.indicator {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-self: center;

	color: var(--color-purewhite);
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
	padding: 5px 6px;
	border: none;
	outline: 0;
	text-decoration: none;
	border-radius: 30px; // height
	cursor: pointer;
	transition: opacity 100ms ease-in-out;

	&:active {
		& > * {
			opacity: .6;
			transition: opacity 100ms ease-in-out;
		}
	}

	& > * {
		margin: 0 6px;
	}

	&__icon {
	}

	&__text {
		text-align: center;
		font-weight: bold;
		font-size: var(--font-size-normal);
		line-height: 20px;
	}
}
