.with-tab-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: calc(var(--metric-windowHeight) - var(--metric-tabBarHeight));

	overflow: hidden;

	&__header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		flex-shrink: 1;
	}

	&__content {
		z-index: 2;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow: scroll;
	}
}
