.full-page-loader {
	background-color: var(--color-purewhite);

	z-index: 1;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__icon {
		padding-bottom: 2rem;
	}
}
