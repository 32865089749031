.sms-settings-container {
	padding-top: 27px;

	.input-add-list-item {
		margin-top: 27px;
	}

	.icon-wrapper {
		display: flex;
	}
}
