.primary-button {
	background-color: var(--color-primary);

	&:disabled {
		opacity: .7;
	}

	.primary-button__text {
		display: flex;
		justify-content: center;
		align-items: center;

		font-family: var(--font-type-ubuntu-bold);
		font-size: var(--font-size-normal);
	}
}
