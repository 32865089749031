.storage-scanner {
	display: flex;
	flex-direction: column;
	height: 100%;

	.storage-scanner__message {
		font-size: var(--font-size-large);
		color: var(--color-darkGrey);
		text-align: center;
		padding: 1rem 1.3rem;
		line-height: 21.8px;

		b,
		strong {
			font-family: var(--font-type-openSans-bold);
		}
	}

	.storage-scanner__error-message {
		font-size: var(--font-size-large);
		color: var(--color-darkOrange);
		text-align: center;
		padding: .5rem 1.3rem 0 1.3rem;
		line-height: 21.8px;

		b,
		strong {
			font-family: var(--font-type-openSans-bold);
		}
	}

	.storage-scanner__button-container {
		padding: 0 35px 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: auto;
	}

	.storage-scanner__button-container__test {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
	}
}
