.settings-list {
	.list-item {
		border-radius: 0;
		box-shadow: 0 1px 0 rgba(210, 210, 210, 0.25);
		margin-bottom: 1px;

		.list-item__content {
			justify-content: space-between;
			line-height: 1.75rem;

			.content__label {
				font-family: var(--font-type-openSans-semiBold);
				font-size: var(--font-size-large);
				color: var(--color-boldGrey);

				&.content__label--red {
					color: var(--color-red);
				}
			}

			.content__value {
				font-family: var(--font-type-openSans-regular);
				font-size: var(--font-size-large);
				color: var(--color-midGrey);

				margin-right: 16px;
			}
		}
	}
}
