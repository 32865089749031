.confirm-time {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 30px 20px;

	h1 {
		text-align: center;
		padding: 14px 40px 30px 40px;
	}

	p {
		margin-bottom: 40px;
		font-size: var(--font-size-xxlarge);
		color: var(--color-boldGrey);
	}

	button {
		display: block;

		svg {
			margin-right: 10px;
		}
	}
}
