.backoffice-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	h3{
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.backoffice__options{
		width: 100%;
	}
}
