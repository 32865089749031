.force-close {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 20px 20px;

	.force-close__icon,
	h1 {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 20px;
		text-align: center;
		font-size: var(--font-size-large);
		color: var(--color-boldGrey);
	}

	.force-close__form {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 1rem;

		button {
			margin-top: 1rem;
		}
	}

	.force-close__buttons {
		width: 100%;
		margin-bottom: 1rem;
	}
}
