.outgoing-change-status {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 20px 20px;

	.helpdesk__icon, h1 {
		margin-bottom: 20px;
	}

	.helpdesk__info {
		background-color: var(--color-veryLightGrey);
		width: 100%;
		padding: 20px 0;
		text-align: center;
		border-radius: 10px;
		margin-bottom: 22px;

		> h2 {
			margin-bottom: 12px;
		}

		.info__name {
			font-family: var(--font-type-ubuntuRegular);
			color: var(--color-nightBlue);
		}
	}

	.helpdesk__buttons {
		width: 100%;

		button {
			display: block;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.helpdesk__form {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 20px;
	}

	.picture-preview {
		&__list {
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;

			&__item {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 5px 20px 5px 20px;
				margin: 10px 0 10px 0;
				border-radius: 15px;
				background-color: var(--color-systemGrey);


				&__text {
					font-family: var(--font-type-ubuntuBold);
					color: var(--color-nightBlue);
					font-size: var(--font-size-xlarge);
					margin-left: 25px;
				}

				&__thumb {
					height: 40px;
					max-width: 40px;
					object-fit: cover;
					margin-left: 3px;
					margin-right: 7px;
				}

				&__delete {
					margin-right: 5px;
					margin-left: auto;

				}
			}

			.add {
				border: 2px var(--color-nightBlue) dashed;
				background-color: transparent;
			}
		}
	}


}
