.outgoing-search {
	position: sticky;
	top: 1px;
	left: 0;
	right: 0;
	flex-shrink: 1;
	z-index: 3;

	padding: 10px 20px;

	background-color: var(--color-purewhite);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.outgoing-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	&__indicator-late {
		margin: 20px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}
