.text-area {
	margin-bottom: 20px;

	textarea {
		background-color: var(--color-purewhite);
		border: 1px solid var(--color-lightGrey);
		box-sizing: border-box;
		padding: 1rem;
		border-radius: 10px;

		width: 100% !important;

		font-size: var(--font-size-normal);
		color: var(--color-text);
		line-height: 20px;
		font-family: var(--font-type-openSans-regular);
		margin-bottom: 0;

		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&::placeholder {
			font-family: var(--font-type-openSans-regular);
			font-size: var(--font-size-normal);
			color: var(--color-midGrey);
		}

		&.not-resizable {
			resize: none;
		}
	}

	.error{
		font-size: var(--font-size-small);
		font-family: var(--font-type-openSans-regular);
		color: var(--color-red);
		margin-top: 4px;
		margin-left: 1rem;
	}
}