.return-orders {

	display: flex;
	flex-direction: column;
	align-items: center;

	.return-orders__icon {
		margin-top: 2rem;
	}

	.return-orders__description {
		color: var(--color-nightBlue);
		font-size: var(--font-size-xlarge);
		font-family: var(--font-type-openSans-regular);
		text-align: center;
		margin: 2rem;
	}
}
