.orderLate-header{
  display: flex;
  .info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__barcode {
      font-size: var(--font-size-normal);
    }
  }
}
.card-body{
	padding-top: 4rem;
	padding-bottom: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;

	&__content{
		display: flex;
		flex-direction: column;
	}

	&__warning{
		display: flex;
		flex-direction: row;
		column-gap: 1rem;
		padding-bottom: 1rem;
		align-items: center;
	}

   &__text{
	   display: flex;
	   flex-direction: column;
   }
}

.info__barcode{
  font-size: var(--font-size-normal);
  color: var(--color-darkGrey)
}

.button-container__late-actions{
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  row-gap: 0.5rem;

  .action-container__decline {
    flex: 1;
    background-color: var(--color-red);
  }
}

