.storage-container {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	flex-shrink: 1;
	z-index: 3;

	background-color: var(--color-systemgrey);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	overflow: hidden;
}

.list-wrapper {
	flex: 1;
}

.storage__button-container {
	position: fixed;
	bottom: calc(var(--metric-tabBarHeight) + 1rem);
	left: 2rem;
	right: 2rem;

	@media (min-width: 768px) {
		left: calc((100vw - 768px) / 2 + 2rem);
		right: calc((100vw - 768px) / 2 + 2rem);
	}
}
