.list-item {
	background-color: var(--color-purewhite);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
	border-radius: 10px;

	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	align-self: stretch;

	padding: 1rem;

	&.list-item--active {
		&:active {
			> * {
				opacity: .6;
				transition: opacity 100ms ease-in-out;
			}
		}
	}

	&.disabled {
		pointer-events: none;
	}

	.list-item__content {
		display: flex;
		flex-direction: row;
		max-width: 100%;
		flex-grow: 1;
		align-items: center;
		align-self: stretch;
	}

	.chevron {
		display: flex;
		flex-shrink: 1;
	}
}
