.order-list-item {
	font-size: var(--font-size-normal);
	line-height: 20px;
	transition: opacity 100ms ease-in-out;
}

.square {
	$square-size: 60px;
	width: $square-size;
	height: $square-size;
	min-width: $square-size;

	margin-right: 1rem;
}

.info {
	&__name {
		font-family: var(--font-type-ubuntu-bold);
	}

	&__barcode {
		font-size: var(--font-size-normal);
	}
}

.sequence-box {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: 1rem;
	width: 40px;
	height: 40px;
	border: 2px solid;
	border-radius: 10px;

	&__number {
		font-family: var(--font-type-ubuntu-bold);
		font-size: var(--font-size-large);
	}
}
