.totes-scanner {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex: 1;

	.totes-scanner__instruction {
		flex: 1;
		font-size: var(--font-size-large);
		color: var(--color-darkGrey);
		text-align: center;
		padding: 1rem 1rem 0 1rem;
		line-height: 1.2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		p {
			padding: 1rem 0;
			white-space: pre-wrap;
		}
	}

	.totes-scanner__scan-container {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;

		margin: 1rem;
	}

	.totes-scanner__scan-status {
		flex-shrink: 1;
		font-size: var(--font-size-large);
		color: var(--color-nightBlue);
		font-family: var(--font-type-openSans-bold);
		text-align: center;
	}

	.totes-scanner__button-container {
		flex: 1;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.button {
			padding: .85rem;
			font-family: var(--font-type-ubuntu-bold);
		}
	}
}
