.storage-item-list {
	display: flex;
	align-items: center;
	flex-direction: column;

	position: relative;

	overflow: hidden;

	padding: 34px 20px 20px 20px;

	.storage-item-list__icon {
		margin-bottom: 14px;
	}

	h1 {
		margin-bottom: 20px;
		text-align: center;
	}

	.storage-item-list__list-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		overflow: scroll !important;

		min-height: 100px;

		max-height: 45vh;

		padding: 1rem 0;

		flex-grow: 1;

		.label {
			padding: 1rem;
			text-align: center;
		}
	}

	.storage-item-list__buttons {
		width: 100%;
		flex-shrink: 1;

		button {
			display: block;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
