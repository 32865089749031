.return-totes {

	display: flex;
	flex-direction: column;
	align-items: center;

	.return-totes__icon {
		margin-top: 2rem;
	}

	.return-totes__description {
		color: var(--color-nightBlue);
		font-size: var(--font-size-xlarge);
		font-family: var(--font-type-openSans-regular);
		text-align: center;

		margin: 2rem;

		strong,
		b {
			font-family: var(--font-type-openSans-bold);
		}
	}

	.return-totes__button-icon {
		margin-right: .5rem;
	}
}
