.badge {
	font-size: var(--font-size-large);
	font-family: var(--font-type-ubuntu-bold);
	text-align: center;
	color: var(--color-purewhite);

	border-radius: 40px;
	background-color: var(--color-nav);

	padding: .15rem;
	width: 40px;


	&.active {
		background-color: var(--color-brightBlue);
	}
}
