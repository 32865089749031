.page-not-found {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 1rem;

	justify-content: center;

	.page-not-found__404 {
		text-align: center;
		font-size: 3rem;
		font-family: var(--font-type-ubuntu-bold);
		color: var(--color-nightBlue);
		margin-bottom: 1rem;
	}

	h1 {
		text-align: center;
		margin-bottom: 1rem;
	}

	p {
		margin: 1rem;
		text-align: center;
	}
}
