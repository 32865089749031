.order-actions {
	display: flex;
	flex-direction: column;
	overflow: scroll;

	h1 {
		font-size: var(--font-size-xlarge);
		font-family: var(--font-type-ubuntu-regular);
		font-weight: 700;

		@media (max-width: 360px) {
			font-size: var(--font-size-large);
		}
	}

	.item__icon {
		margin-right: 10px;
	}

	.navigate-order__icon {
		float: left;
	}

	.order-actions__header {
		.order-navigation__header {
			display: flex;
			padding-left: 1rem;
			padding-right: 1rem;
			justify-content: space-between;
			background-color: var(--color-purewhite);

			.icon-button:disabled{
				background-color: transparent !important;
				color: var(--color-lightGrey);
			}
			.icon-button{
				color: var(--color-blue);
			}

			.header__details {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-shrink: 1;

				padding: 1rem 0;


				.details__name {
					text-align: center;
					font-family: var(--font-type-ubuntu-regular);
					font-size: var(--font-size-xlarge);
				}

				.details__info {
					align-self: stretch;
					display: flex;
					margin-top: 1rem;

					.info__item {
						display: flex;
						align-items: center;
						margin-left: 1rem;

						&:nth-child(odd) {
							margin-left: unset;
							margin-right: 1rem;
							justify-content: flex-end;
						}
					}
				}
			}
		}

		.header__location {
			display: flex;
			align-items: center;
			flex-shrink: 1;

			padding: 1rem;

			background-color: var(--color-nightBlue);

			h1 {
				color: var(--color-purewhite);
			}

			.location__delimiter {
				width: 1px;
				background-color: #27528f;
				height: 40px;
			}

			.location__item {
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;

				font-size: var(--font-size-xlarge);
				font-family: var(--font-type-ubuntu-regular);
				font-weight: 700;
			}
		}
	}

	.order-actions__hrl-container {
		margin: 1rem;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
		background-color: var(--color-purewhite);
	}

	.order-actions__button-container {
		margin: 0 1rem;

		& > button {
			// honeywell css fix
			display: flex;
			justify-content: center;
			align-items: center;

			margin: 0.5rem 0;
			padding: unset;

			// fixed size due icons
			height: 50px;
		}
	}
}
