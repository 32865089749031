.honeywell-scanner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		padding-bottom: 1rem;
	}
}
