.order-navigation__container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-purewhite);
  padding: 1rem;

  .icon-button:disabled{
    background-color: transparent !important;
    color: var(--color-lightGrey);
  }

  .icon-button{
    color: var(--color-blue);
  }
  .display__title{}

}


