.with-navbar-header {
	display: flex;
	flex-direction: column;

	flex-grow: 1;

	overflow-y: scroll;
	overflow-x: hidden;

	&__header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		flex-shrink: 1;
	}

	&__content {
		z-index: 2;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}
