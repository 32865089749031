.outgoing-cancel {
	display: flex;
	align-items: center;
	flex-direction: column;

	padding: 34px 20px 20px 20px;

	.outgoing-cancel__icon {
		margin-bottom: 14px;
	}

	h1 {
		margin-bottom: 20px;
		text-align: center;
	}

	.outgoing-cancel__buttons {
		width: 100%;

		button {
			display: block;
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
