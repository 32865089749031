.boxed-container {
	display: flex;
	flex-direction: column;

	flex: 1;

	position: relative;
	overflow: hidden;

	/* mobile viewport bug fix */
	height: var(--metric-windowHeight);

	width: 100vw;
	max-height: var(--metric-windowHeight);

	box-sizing: border-box;
	padding-bottom: env(safe-area-inset-bottom);

	@media (min-width: 768px) {
		height: var(--metric-windowHeight);
		max-height: var(--metric-windowHeight);
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

		width: 768px;
	}
}
