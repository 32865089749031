.icon-button {
	display: flex;
	align-items: center;
	justify-content: center;

	background: none;
	border: none;
	text-decoration: none;

	cursor: pointer;

	transition: opacity 100ms ease-in-out;

	&:active {
		> * {
			opacity: .6;
			transition: opacity 100ms ease-in-out;
		}
	}

	&:disabled {
		background-color: var(--color-midGrey) !important;
		cursor: auto;
	}

	&--has-children {
		svg {
			margin-right: 10px;
		}
	}
}
