.search-bar {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	&__icon {
		position: absolute;
		left: 12px;
	}

	&__input {
		width: 100%;
		padding: 10px 40px;
		border: none;

		outline: 0;
		box-sizing: border-box;
		border-radius: 40px;

		font-family: var(--font-type-openSans-regular);
		color: var(--color-nightBlue);
		font-size: var(--font-size-normal);
		line-height: 20px;

		background-color: #F5F5F5;

		&::placeholder {
			color: var(--color-midGrey);
			opacity: 1;
		}
	}

	&__close {
		position: absolute;
		right: 15px;
	}
}
