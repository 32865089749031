.pwa-scanner {
	position: relative;
	height: 50vh;
	width: 100%;
	overflow: hidden;

	.video {
		flex: 1;
		height: 100%;
		width: 100%;
		display: flex;
		overflow: hidden;
	}

	.video video {
		min-width: 100%;
		flex: 1;
	}
}
