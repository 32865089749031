.order-status-label {
	padding: .5rem;
	border-radius: 5px;

	color: var(--color-purewhite);
	font-size: var(--font-size-xxlarge);
	font-family: var(--font-type-ubuntu-bold);
	text-align: center;

	max-width: 50px;

	position: relative;

	.icon-container {
		position: absolute;
		top: -9px;
		right: -12px;
		background-color: var(--color-purewhite);
		height: 26px;
		width: 26px;
		border-radius: 26px;

		display: flex;
		align-items: center;
		justify-content: center;

		&__icon {
			margin: auto;
		}
	}
}
