.storage-state__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: var(--color-purewhite);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
	border-top: 1px solid #f0f0f0;
	z-index: 5;

	.storage-state__item {
		display: flex;
		margin: 12px 0 12px 20px;
		border-right: 1px solid #f0f0f0;
		width: 100%;
		align-items: center;

		&:last-of-type {
			border-right: none;
		}

		&-quantity {
			margin-left: 10px;

			&-number {
				color: var(--color-nightBlue);
				font-family: var(--font-type-openSans-semiBold);
				font-size: var(--font-size-xlarge);
			}

			&-category {
				color: var(--color-nightBlue);
				font-family: var(--font-type-openSans);
				font-size: var(--font-size-normal);

				text-transform: uppercase;
			}
		}
	}
}
