.secondary-button {
	background-color: var(--color-green);

	.secondary-button__text {
		display: flex;
		justify-content: center;
		align-items: center;

		font-family: var(--font-type-ubuntu-bold);
		font-size: var(--font-size-normal);
		color: var(--color-purewhite);
	}
}
