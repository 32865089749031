.hrl {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;

	flex: 1;

	padding: 1rem;

	font-size: var(--font-size-small);

	.hrl__identification {
		flex: 1;

		max-width: 35%;

		span {
			display: block;

			&.identification__code {
				font-size: 2.5rem;
				font-family: var(--font-type-ubuntu-bold);
				margin-bottom: .5rem;

				@media (max-width: 360px) {
					font-size: 2rem;
				}
			}

			&.identification__pally {
				font-size: var(--font-size-xxxlarge);
				font-family: var(--font-type-ubuntu-bold);
				margin-left: 5px;
				margin-bottom: 1rem;
				@media (max-width: 360px) {
					font-size: var(--font-size-xxlarge);
				}
			}

			&.identification__orderIdTitle, &.identification__alternateClientOrderReference {
				font-size: var(--font-size-xlarge);
				font-family: var(--font-type-ubuntu-bold);
				margin-bottom: .5rem;
				margin-left: 5px;
				margin-top: 2rem;
				word-wrap: break-word;
				@media (max-width: 360px) {
					font-size: var(--font-size-large);
				}
			}

			 &.identification__alternateClientOrderReference {
				font-size: var(--font-size-normal);
				font-family: var(--font-type-ubuntu-bold);
				@media (max-width: 360px) {
					font-size: var(--font-size-normal);
				}
			}


			&.identification__orderId {
				font-size: var(--font-size-normal);
				font-family: var(--font-type-ubuntu-bold);
				margin-left: 5px;
				max-width: 100%;
			}
		}
	}
	.label__location {
		flex: 1;
		padding: 0 1rem;
		display: flex;
		flex-direction: column;

		.location__totes {
			font-size: var(--font-size-xxxlarge);
			font-family: var(--font-type-ubuntu-bold);

			@media (max-width: 360px) {
				font-size: var(--font-size-xxlarge);
			}
		}

		.location__store-icon {
			margin-left: .5rem;
			margin-top: .5rem;
		}
	}
	.label__address {
		flex: 2;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		filter: blur(2.5px);

		.address__date {
			font-size: var(--font-size-xxsmall);
			margin-bottom: 1rem;
			@media (max-width: 360px) {
				font-size: .45rem;
			}
		}

		.address__name,
		.address__store {
			font-family: var(--font-type-openSans-bold);
			font-size: var(--font-size-small);
			margin-bottom: 1rem;

			@media (max-width: 360px) {
				font-size: var(--font-size-xsmall);
			}
		}

		.address__description {
			font-family: var(--font-type-openSans-bold);
			font-size: var(--font-size-xsmall);
			margin-bottom: 1rem;

			@media (max-width: 360px) {
				font-size: var(--font-size-xxsmall);
			}
		}
		.address__extra {
			font-family: var(--font-type-openSans-regular);
			font-size: var(--font-size-xsmall);

			@media (max-width: 360px) {
				font-size: var(--font-size-xxsmall);
			}

			span {
				display: block;
			}
		}
	}
}
