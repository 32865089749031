.scanner-container {
	position: relative;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__scan-area {
		height: 100%;
		width: 100%;
		display: flex;
	}

	.scanner-switch {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 1rem;

		&.disabled {
			opacity: .4;
		}

		&__option {
			padding: 1rem;

			font-family: var(--font-type-openSans-regular);
			font-size: var(--font-size-small);

			&.selected {
				font-family: var(--font-type-openSans-bold);
			}
		}
	}
}
