.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: env(safe-area-inset-bottom);
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;

	z-index: 10;

	@media (min-width: 768px) {
		left: calc((100vw - 768px) / 2);
		right: calc((100vw - 768px) / 2);
	}
}

