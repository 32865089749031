.offline-warning {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 34px 20px 20px 20px;

	.offline-warning__icon {
		margin-top: 2rem;
	}

	.offline-warning__description {
		color: var(--color-nightBlue);
		font-size: var(--font-size-xlarge);
		font-family: var(--font-type-openSans-regular);
		text-align: center;
		margin: 2rem;
	}
}
