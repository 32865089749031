.button {
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: flex-start;

	min-width: 150px;
	border: none;
	text-decoration: none;
	border-radius: 50px;

	padding: 1.1rem;

	cursor: pointer;

	color: var(--color-purewhite);
	font-family: var(--font-type-openSans-semiBold);
	font-size: var(--font-size-normal);

	&.disabled {
		opacity: .4;
		pointer-events: none;
	}
}
