.update-app {
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		text-align: center;
		padding: 1rem;
	}

	button {
		margin: 1rem 0;
	}
}
