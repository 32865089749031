.outgoing-change-time {
	display: flex;
	flex-direction: column;
	padding: 33px 20px 140px 20px;
	overflow: auto;

	h1 {
		text-align: center;
		margin-bottom: 30px;
	}

	.timeslot-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.timeslot {
			display: flex;
			flex-basis: 48%;
			justify-content: center;

			padding: 12px 0;
			margin-bottom: 10px;

			font-family: var(--font-type-ubuntu-regular);
			font-size: var(--font-size-xlarge);
			line-height: 1.1325rem;

			border-radius: 10px;
			background-color: var(--color-purewhite);
			color: var(--color-darkGrey);
			box-shadow: 0 5px 10px rgb(0 0 0 / 3%);

			&.timeslot--active {
				background-color: var(--color-brightBlue);
				color: var(--color-purewhite);
			}

			&.timeslot--disabled {
				background-color: var(--color-lightGrey);
				color: var(--color-midGrey);
			}
		}
	}

	.outgoing-change-time__buttons {
		position: fixed;
		right: 20px;
		bottom: 80px;
		left: 20px;

		@media (min-width: 768px) {
			left: calc((100vw - 768px) / 2 + 20px);
			right: calc((100vw - 768px) / 2 + 20px);
		}

		button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
