.tabBar {
	display: flex;
	flex-direction: row;
	flex-shrink: 1;

	min-height: var(--metric-tabBarHeight);
	height: var(--metric-tabBarHeight);
	max-height: var(--metric-tabBarHeight);

	background-color: var(--color-purewhite);
	padding-bottom: env(safe-area-inset-bottom);

	@media (min-width: 768px) {
		left: calc((100vw - 768px) / 2);
		right: calc((100vw - 768px) / 2);
	}
}
