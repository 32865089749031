.storage-list-item {
	display: flex;
	align-items: center;
	padding: 20px;
	background-color: var(--color-purewhite);

	&__content {
		display: flex;
		flex-direction: column;
		padding: 0 15px;

		&-title {
			font-family: var(--font-type-ubuntu-bold);
			color: var(--color-brightBlue);
			font-size: var(--font-size-xlarge);
			line-height: 20px;

			&--grey {
				color: var(--color-darkGrey);
				font-size: var(--font-size-xlarge);
				line-height: 20px;
				font-family: var(--font-type-ubuntu-bold);
			}
		}

		&-info {
			font-family: var(--font-type-OpenSans);
			color: var(--color-darkGrey);
			font-size: var(--font-size-normal);
			line-height: 20px;
		}
	}
}

.item-delete-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;

	font-weight: 700;
	font-size: var(--font-size-small) !important;
	text-transform: uppercase;

	border-radius: 10px;

	svg {
		width: 18px;
		margin-right: unset;
	}
}
